<template>
  <layout-template>
    <template slot="main">
      <list-template>
        <template v-slot:header>
          <div class="flex flex-between-center container header1">
            <div>
              <div class="nickname">{{userInfo.name || ''}}</div>
              <div class="department">{{tenantName || ''}}</div>
            </div>
            <div class="logout flex flex-center" @click="onLogout">注销登录</div>
          </div>
        </template>
        <div>
          <div class="flex flex-col">
            <div class="header">
              <div class="search-add">
                <div class="search-wrapper">
                  <img
                    :src="search"
                    class="icon"
                    @click="onRefresh"
                  >
                  <input
                    v-model="query.condition.keyword"
                    placeholder="退款单/订单编号/姓名/手机号/学号或工号搜索"
                    class="content"
                    @focus="isfocused=true"
                    @blur="onblur"
                    @keyup.enter="onRefresh"
                  >
                  <div v-if="query.condition.keyword" @click="clearText()">
                    <img
                      :src="clear"
                      class="icon"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <group-checkbox :options="options" v-model="defaultOption" @change="onStatusChange" style="margin-left:15px;margin-right:15px"></group-checkbox>
        <bscroll
          v-if="refunds && refunds.length>0"
          ref="scroll"
          :scrollbar="scrollbarObj"
          :pullDownRefresh="pullDownRefreshObj"
          :pullUpLoad="pullUpLoadObj"
          :startY="parseInt(startY)"
          @pullingDown="onRefresh"
          @pullingUp="onMore"
          :data="refunds"
          class="main"
        >
          <div class="content">
            <div
              class="card-wrapper"
              v-for="item in refunds"
              :key="item.id"
              @click="gotoPage(item.id)"
            >
              <div>
                <div class="flex" style="align-items: center;justify-content: space-between;margin-bottom: 10px;">
                  <div style="font-weight: bold;">{{ item.id }}</div>
                  <div :style="colorRefund(item)">{{item.refundStatus.title}}</div>
                </div>
                <div class="flex-row" style="align-items: stretch;margin-bottom: 10px;font-size: 13px;">
                  <div>订单号：{{ item.orderSn }}</div>
                </div>
                <div class="flex-row" style="align-items: stretch;margin-bottom: 10px;font-size: 13px;">
                  <div>设备SN：{{ item.sn }}</div>
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 10px;font-size: 13px;">
                  <div >申请人：{{ item.userName }} | {{ item.userNumber }} | {{ item.userPhone }}</div>
                </div>
                <div class="flex-row" style="align-items: stretch;font-size: 13px;">
                  <div>设备位置：{{ item.address }}</div>
                </div>
              </div>
            </div>
          </div>
        <div style="height:150px"></div>
        </bscroll>
        <div
          v-else
          class="flex flex-column flex-center forbidden-wrapper"
          :style="{backgroundImage:'url(' + forbidden + ')', backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundSize: 'contain'}"
        >
          <div>暂无数据</div>
          <!-- <div style="margin-top:10px;color:#555">点击刷新</div> -->
        </div>
      </list-template>
    </template>
  </layout-template>
</template>
<script>
// import baseCheckbox from '@/components/base-checkbox'
import clear from '@/assets/clear.svg'
import search from '@/assets/search@3x.png'
import refundApi from '@/api/refund.js'
import bscroll from '@/components/bscroll'
import forbidden from '@/assets/forbidden@3x.png'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import GroupCheckbox from '@/components/group-checkbox'

export default {
  components: {
    GroupCheckbox,
    bscroll
  },
  computed: {
    ...mapGetters(['userInfo', 'tenantName', 'tenantId']),
    scrollbarObj: function () {
      return this.scrollbar ? { fade: this.scrollbarFade } : false
    },
    pullDownRefreshObj: function () {
      return this.pullDownRefresh
        ? {
          threshold: parseInt(this.pullDownRefreshThreshold),
          stop: parseInt(this.pullDownRefreshStop)
        }
        : false
    },
    pullUpLoadObj: function () {
      return this.pullUpLoad
        ? {
          threshold: parseInt(this.pullUpLoadThreshold),
          txt: {
            more: this.pullUpLoadMoreTxt,
            noMore: this.pullUpLoadNoMoreTxt
          }
        }
        : false
    }
  },
  data () {
    return {
      defaultPhoneHeight: document.documentElement.clientHeight,
      nowPhoneHeight: null,
      url: location.href.split('#')[0],
      scrollbarFade: true,
      pullDownRefresh: true,
      pullDownRefreshThreshold: 90,
      pullDownRefreshStop: 40,
      pullUpLoad: true,
      pullUpLoadThreshold: 0,
      pullUpLoadMoreTxt: '加载更多',
      pullUpLoadNoMoreTxt: '没有数据啦',
      startY: 0,
      scrollToX: 0,
      scrollToY: -200,
      scrollToTime: 700,
      scrollToEasing: 'bounce',
      scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],
      query: {
        pageInfo: {
          pageIndex: 1,
          pageSize: 10
        },
        condition: {
          keyword: null,
          refundStatus: null
        }
      },
      refunds: [],
      currentScanRouter: '',
      clear,
      search,
      forbidden,
      defaultOption: null,
      options: [
        { name: '全部', value: null },
        { name: '待审核', value: 'PENDING' },
        { name: '已退款', value: 'DONE' },
        { name: '已拒绝', value: 'REFUSE' },
      ],
      isfocused: false,
      isshow: false,
      last: false,
      scroll: '',
      bottomTip: '',
      rows: 0
    }
  },
  methods: {
    colorRefund (value) {
      if (value.refundStatus && value.refundStatus.name === 'PENDING') {
        return 'color:#909399'
      } else if (value.refundStatus && value.refundStatus.name === 'REFUNDING') {
        return 'color:#db6127'
      } else if (value.refundStatus && value.refundStatus.name === 'DONE') {
        return 'color:#67c23a'
      } else if (value.refundStatus && value.refundStatus.name === 'REFUSE') {
        return 'color:#f56c6c'
      } else if (value.refundStatus && value.refundStatus.name === 'FAIL') {
        return 'color:#f56c6c'
      } else if (value.refundStatus && value.refundStatus.name === 'CANCEL') {
        return 'color:#e6a23c'
      } else {
        return 'color:#000000'
      }
    },
    onLogout () {
      this.$store.dispatch('Logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    onGetTotal (total) {
        this.total = total
    },
    gotoPage (id) {
      this.$router.push({ name: 'refundDetail', params: { id: id } })
    },
    onblur () {
      this.isfocused = !!this.key
    },
    clearText () {
      this.query.condition.keyword = null
      this.onRefresh()
    },
    onStatusChange (value) {
      this.query.condition.refundStatus = value
      this.onRefresh()
    },
    onMore () {
      this.query.pageInfo.pageIndex++
      this._loadData()
    },
    onRefresh () {
      this.refunds = []
      this.query.pageInfo.pageIndex = 1
      this._loadData()
    },
    clearCon () {
      this.query.condition.keyword = ''
      this._loadData()
    },
    rebuildScroll () {
      Vue.nextTick(() => {
        this.$refs.scroll.destroy()
        this.$refs.scroll.initScroll()
      })
    },
    _loadData () {
      refundApi.getPaged({ pageInfo: this.query.pageInfo, condition: this.query.condition })
        .then((res) => {
          const refunds = this.refunds
          if (res && res.numberOfElements > 0) {
            this.last = res.last
            refunds.push(...res.content)
          }
        })
    },
  },
  watch: {
    nowPhoneHeight (val) {
      if (this.defaultPhoneHeight !== val) {
        // 手机键盘被唤起了。
      } else {
        // 手机键盘被关闭了。
        this.onRefresh()
      }
    },
    scrollbarObj: {
      handler () {
        this.rebuildScroll()
      },
      deep: true
    },
    pullDownRefreshObj: {
      handler () {
        this.rebuildScroll()
      },
      deep: true
    },
    pullUpLoadObj: {
      handler () {
        this.rebuildScroll()
      },
      deep: true
    },
    startY () {
      this.rebuildScroll()
    }
  },
  created () {
    this.onRefresh()
  },
  mounted () {
    window.onresize = () => {
      console.debug('onresize')
      this.nowPhoneHeight = document.documentElement.clientHeight
    }
  }
}
</script>
<style lang="stylus" scoped>
  .header1
    padding 0 32rpx
  .nickname
    font(32,48,#fff)
    margin-bottom 8rpx
  .department
    font(24,40,rgba(255,255,255,.65))
    ellipse(500)
  .logout
    width:128rpx;
    height:48rpx;
    background:rgba(255,255,255,0.35);
    border-radius:24rpx;
    font(24,40,#fff)
  .block
    background #fff
    border-radius 20rpx
    margin 40rpx 32rpx
    height 112rpx
    min-height 20px
    padding 0 32rpx
    font(28,44,$text)
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.header {
  flex: 0 0 auto;
  background-color:#453796;
  box-sizing: border-box;
  padding: 10px 15px 10px;
  height : 45px;
  .search-add {
    background-color: #43318e;
    // display: flex;
    justify-content: space-between;
    // margin-bottom: 40px;
    .search-wrapper {
      background-color: rgba(12,12,58,0.45);
      flex: 0 0 360px;
      height: 38px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      .icon {
        flex: 0 0 28px;
        height: 80%;
      }
      .content {
        flex: auto;
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 15px;
        line-height: 30px;
        caret-color: #fff;
        color: #fff;
      }
      .clear-wrapper {
        flex: 0 0 52px;
        margin-right: 22px;
        box-sizing: border-box;
        padding: 5px;
        .xy-delete_highlight {
          color: #f0f9eb
          font-size: 42px;
        }
      }
    }
    .dropdown {
      height: 68px;
      flex: 0 0 68px;
    }
  }
  .search-options {
    display: flex;
    justify-content: space-between;
    .item {
      flex: 1;
    }
  }
}
.search-options2 {
    display: flex;
    justify-content: space-between;
    .item {
      flex: 1;
    }
  }

.main {
  flex: auto;
  .tip-wrapper {
    padding: 20px 20px 0;
    .bottom {
      text-align: center;
      font-size: 32px;
      color: #bbb;
    }
  }
  .content {
    .card-wrapper {
      background-color: white;
      border-radius: 10px;
      margin: 5px 15px;
      padding: 20px 20px 0;
      &:last-child {
        padding-bottom: 20px;
      }
    }
  }
  .forbidden-wrapper {
    height: 460px;
    width: 400px;
    font-family: 'PingFangSC-Medium';
    font-size: 20px;
    color: #b3b3b3;
  }
}

.dropdown-container {
  position: fixed;
  z-index: 7000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);

  .dropdown-menu {
    position: absolute;
    background-color: #fff;
    right: 20px;
    top: 152px;
    width: 320px;
    // height: 330px;
    box-sizing: border-box;
    padding: 10px 0;
    margin: 0;
    border-radius: 8px;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-bottom-color: #fff;
      position: absolute;
      top: -17px;
      right: 25px;
    }

    .dropdown-item {
      padding: 0 16px;
      display: block;
      text-decoration: none;
      background-color: #fff;

      &:last-child {
        .text {
          border-bottom: none;
        }
      }

      .text {
        border-bottom: 2px solid #dbdbdb;
        padding: 33px 0;
        font-size: 32px;
        text-align: center;
        color: #000;
      }

      &:active {
        background-color: #f0f0f0;

        .text {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
